// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
//import './plugins/lazyload';
import './plugins/modernizr.min';
import LazyLoad from 'vanilla-lazyload';
import barba from '@barba/core';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import 'leaflet';
/* global ajax_object */
/* global L */
var windowWidth = $(window).width();
/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * BarbsJS init
   */
  barba.init({
    timeout: 30000,
    transitions: [
      {
        name: 'basic',
        enter: function enter() {
          $(() => {
            // Make match height.
            $('.js-match-height').matchHeight({
              byRow: true,
              property: 'height',
              target: null,
              remove: false,
            });
          });

          // Init LazyLoad (for ignore .no-lazyload).
          let lazyLoadInstance = new LazyLoad({
            elements_selector: 'img[data-lazy-src],.pre-lazyload',
            data_src: 'lazy-src',
            data_srcset: 'lazy-srcset',
            data_sizes: 'lazy-sizes',
            skip_invisible: false,
            class_loading: 'lazyloading',
            class_loaded: 'lazyloaded',
          });

          // Add tracking on adding any new nodes to body to update lazyload for the new images (AJAX for example).
          window.addEventListener(
            'LazyLoad::Initialized',
            function () {
              // Get the instance and puts it in the lazyLoadInstance variable.
              if (window.MutationObserver) {
                let observer = new MutationObserver(function (mutations) {
                  mutations.forEach(function (mutation) {
                    mutation.addedNodes.forEach(function (node) {
                      if (typeof node.getElementsByTagName !== 'function') {
                        return;
                      }
                      let imgs = node.getElementsByTagName('img');
                      if (0 === imgs.length) {
                        return;
                      }
                      lazyLoadInstance.update();
                    });
                  });
                });
                let b = document.getElementsByTagName('body')[0];
                let config = { childList: true, subtree: true };
                observer.observe(b, config);
              }
            },
            false
          );

          // Update LazyLoad images before Slide change.
          $('.slick-slider').on('beforeChange', function () {
            lazyLoadInstance.update();
          });
        },
        beforeEnter: function beforeEnter() {
          $('html, body').animate(
            {
              scrollTop: 0,
            },
            500
          );

          if (!$('.js-navbar-toggler').hasClass('collapsed')) {
            $('.js-navbar-toggler').trigger('click');
          }
        },
        after: function after() {
          let pageEdit = $(document).find('#bwp-main').data('edit');
          if (pageEdit) {
            $(document).find('#wp-admin-bar-edit > a').attr('href', pageEdit);
          }
          if ($('.form-wrapper').length) {
            let formID = $('.form-wrapper').attr('data-form-id');
            let formRef = $('.form-wrapper');

            $.ajax({
              url: ajax_object.ajax_url,
              type: 'GET',
              data: {
                form_id: formID,
                action: 'get_form',
              },
            }).done(function (response) {
              $('.form-wrapper').html(response);

              setTimeout(function () {
                formRef.find('script').each((index, el) => {
                  window.eval($(el).text());
                });
              }, 1500);
            });
          }

          // Update elements
          slidersInit();
          openMapInit();
          resizeVideo();
          updateHeight();
          updateArrowTop();
          allowProtected();

          // Override bootstrap submenu toggle
          $('a.dropdown-toggle').on('click keyup', function (e) {
            e.preventDefault();
            e.stopPropagation();
            let $this = $(e.target);
            bootstrapSubmenuToggle($this);
          });
        },
      },
    ],
    prevent: ({ el }) =>
      el.classList &&
      (el.classList.contains('ab-item') ||
        el.classList.contains('fancybox-image') ||
        el.hasAttribute('data-fancybox')),
  });

  // Anchor click scrolling
  $(document).on('click', 'a[href^="#"]', function (e) {
    if (
      $(this).attr('href') != '#' &&
      !$(this).hasClass('fancybox') &&
      $(this).attr('role') != 'tab'
    ) {
      e.preventDefault();
      let $this = $(this);
      if ($($this.attr('href')).length) {
        $('html, body').animate(
          {
            scrollTop: $($this.attr('href')).offset().top - 90 + 'px',
          },
          750,
          'swing'
        );
      }
    }
  });

  // On load *only* if we have anchor on the url
  if (window.location.hash) {
    // smooth scroll to the anchor id
    $('html, body').animate(
      {
        scrollTop: $(window.location.hash).offset().top - 90 + 'px',
      },
      750,
      'swing'
    );
  }

  // Override bootstrap submenu toggle
  $('a.dropdown-toggle').on('click keyup', function (e) {
    e.preventDefault();
    e.stopPropagation();
    let $this = $(e.target);

    bootstrapSubmenuToggle($this);
  });

  function bootstrapSubmenuToggle($toggle) {
    if ($toggle === undefined || !$toggle.length) return false;

    if ($toggle.hasClass('js-dropdown-toggle')) {
      $toggle
        .closest('.dropdown')
        .toggleClass('show')
        .find('.dropdown-menu')
        .toggleClass('show');
      $toggle
        .toggleClass('is-active')
        .closest('.dropdown-toggle')
        .attr('aria-expanded', (i, attr) =>
          attr === 'true' ? 'false' : 'true'
        );
    } else if ($toggle.closest('a').attr('href')) {
      barba.go($toggle.closest('a').attr('href'));
    }
  }

  // Show dropdown menu
  $(document).on('mouseover', 'a.dropdown-toggle', function (e) {
    let $this = $(e.currentTarget);
    $this.closest('#primary-menu').find('.dropdown-menu').removeClass('show');
    $this.next('.dropdown-menu').addClass('show');
  });

  let menuTimeout;
  $(document).on('mouseleave', '.header', function () {
    let $this = $(this);
    menuTimeout = setTimeout(function () {
      $this.find('.dropdown-menu').removeClass('show');
    }, 2000);
  });

  $(document).on('mouseover', '.header', function () {
    clearTimeout(menuTimeout);
  });

  // Fancybox content
  $(document).on('click', '.fancybox', function (e) {
    e.preventDefault();
    let sectionID = $(this).attr('href');
    if (sectionID && $(sectionID).length) {
      $.fancybox.open({
        src: sectionID,
        type: 'inline',
        opts: {
          baseClass: 'fancybox_form',
          afterShow: function () {
            console.info('done!');
          },
        },
      });
    }
  });

  // Hotspot open
  $(document).on('click', '.js-hotspot', function (e) {
    e.preventDefault();
    let dataId = $(this).attr('data-id');
    let content = $(this)
      .parent()
      .parent()
      .find('#' + dataId);
    let positionLeft = $(this).parent().position().left;
    let contentWidth = content.outerWidth() + 60;
    let sum = positionLeft + contentWidth;

    $('.hotspot__content').hide();
    $('.hotspot__content').removeClass('right');
    $('.hotspot__content').removeClass('is-active');
    $('.hotspot').removeClass('is-active');
    $(this).closest('.hotspot').addClass('is-active');
    content.addClass('is-active');
    if (sum > windowWidth) {
      content.addClass('right');
    }
  });

  $(document).on('click', '.js-hotspot-close', function (e) {
    e.preventDefault();
    $('.hotspot').removeClass('is-active');
    $(this).parent().removeClass('is-active');
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    let $protected = $('#protected_' + formId);
    if ($protected.length) {
      setCookie('protected_files_allowed', 1, 1);
      allowProtected();
    }
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  // Menu toggle active class x-icon
  $(document).on('click', '.js-navbar-toggler', function () {
    $(this).toggleClass('is-active');
  });

  // Scroll to next
  $(document).on('click', '.js-next-section', function (e) {
    e.preventDefault();
    let $target = $(this).closest('section').next('section');
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 70 }, 500);
    }
  });

  // Download file form
  $(document).on('click', '.js-download', function () {
    $(this).toggleClass('is-active');
    let $filenameField = $(document).find('.populate_filename input');
    let $pagenameField = $(document).find('.populate_pagename input');

    if ($(this).attr('data-filename') != undefined && $filenameField.length) {
      $filenameField.val($(this).attr('data-filename'));
    }

    if ($pagenameField.length) {
      $pagenameField.val(document.title);
    }
  });

  // Scroll to current tab
  $(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function () {
    $('.slick-slider').slick('refresh');
  });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        // TODO: replace with bootstrap toggle
        // $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  // Show hero video slowly
  $('.js-hero-video').on('play', function () {
    $(this).fadeTo('slow', 1);
  });

  // On Document Ready functions
  slidersInit();
  openMapInit();
  resizeVideo();
  updateHeight();
  updateArrowTop();
  allowProtected();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  let changedWidth = $(window).width();
  windowWidth = changedWidth;

  resizeVideo();
  if (windowWidth > 992) {
    updateHeight();
  }

  slidersInit();
  updateArrowTop();
});

/**
 * Scripts which runs on scrolling
 */
let lastScrollTop = 0;
$(window).on('scroll', function () {
  // jQuery code goes here
  let st = $(this).scrollTop();
  $('html')
    .toggleClass('scrolling-top', st < lastScrollTop)
    .toggleClass('scrolling-bottom', st > lastScrollTop);
  if (st === 0) $('html').removeClass('scrolling-top scrolling-bottom');
  lastScrollTop = st;
});

function updateArrowTop() {
  $('.js-flex-slider-section').each(function () {
    let collectieHeight = $(this).find('.js-arrow-height').height();
    if (collectieHeight !== undefined) {
      $(this).css('--arrow-top', collectieHeight + 'px');
    }
  });
}

function updateHeight() {
  $('.js-flex-slider-section').each(function () {
    if ($(this).hasClass('has-info')) {
      let sliderHeight = $(this).find('.flex_slider__info').outerHeight();
      if (windowWidth > 992) {
        $(this)
          .find('.simple__inner')
          .each(function () {
            $(this).height(sliderHeight);
          });
      }
    }
  });
}

function slidersInit() {
  // Posts slider
  $('.js-flex-slider').each(function (index) {
    let toShow = $(this).data('show') || 5;
    let isInfinity = $(this).data('infinity') ? true : false;
    let isAutoplay = $(this).data('autoplay') ? true : false;
    let isLightbox = $(this).data('lightbox') ? true : false;
    $(this).addClass('js-flex-slider--' + index);
    if (isLightbox === true) {
      $(this).addClass('js-flex-slider--lightbox');
    }
    $(this).slick({
      dots: false,
      infinite: isInfinity,
      speed: 600,
      autoplay: isAutoplay,
      autoplaySpeed: 5000,
      slidesToShow: toShow,
      slidesToScroll: 1,
      accessibility: false,
      appendArrows: $(this)
        .closest('.flex_slider')
        .find('.js-flex-slider-arrows'),
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: toShow > 3 ? 3 : toShow,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: toShow > 2 ? 2 : toShow,
          },
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 2,
            arrows: false,
            dots: true,
          },
        },
      ],
    });

    //* Slider Fancy Box
    let selector =
      '.js-flex-slider--' +
      index +
      '.js-flex-slider--lightbox .slick-slide:not(.slick-cloned) a';
    // Skip cloned elements
    $('[data-fancybox^=slider-gallery-]').fancybox({
      selector: selector,
      backFocus: false,
    });
    // Attach custom click event on cloned elements, trigger click event on corresponding link
    // eslint-disable-next-line no-unused-vars
    $(document).on('click', '.slick-cloned', function (e) {
      $(selector)
        .eq(($(this).attr('data-slick-index') || 0) % $(selector).length)
        .trigger('click.fb-start', { $trigger: $(this) });
      return false;
    });
  });

  // Designers slider
  $('.js-designer-slider:not(.slick-initialized)').each(function () {
    $(this).slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 1000,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
  });

  $('.js-designer-info:not(.slick-initialized)').each(function () {
    let $designerSlider = $(this)
      .closest('.flex_designer')
      .find('.js-designer-slider');
    let $designerDots = $(this)
      .closest('.flex_designer')
      .find('.js-designer-dots');
    $(this).slick({
      dots: true,
      arrows: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: $designerSlider,
      appendDots: $designerDots,
    });
  });

  $('.single-slider:not(.slick-initialized)').each(function (index) {
    $(this).addClass('single-slider--' + index);
    $(this).slick({
      dots: true,
      arrows: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      appendArrows: $('.single-slider-controls'),
      appendDots: $('.single-slider-controls'),
    });

    //* Slider Fancy Box
    let selector =
      '.single-slider--' + index + ' .slick-slide:not(.slick-cloned) a';
    // Skip cloned elements
    $('[data-fancybox^=slider-gallery-]').fancybox({
      selector: selector,
      backFocus: false,
    });
    // Attach custom click event on cloned elements, trigger click event on corresponding link
    // eslint-disable-next-line no-unused-vars
    $(document).on('click', '.slick-cloned', function (e) {
      $(selector)
        .eq(($(this).attr('data-slick-index') || 0) % $(selector).length)
        .trigger('click.fb-start', { $trigger: $(this) });
      return false;
    });
  });

  $('.js-material-slider:not(.slick-initialized)').slick({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: $('.js-material-list, .js-material-titles'),
  });

  $('.js-material-list:not(.slick-initialized)').slick({
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
    asNavFor: $('.js-material-slider, .js-material-titles'),
  });

  $('.js-material-titles:not(.slick-initialized)').slick({
    dots: false,
    arrows: false,
    infinite: true,
    fade: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  // Mobile slider
  $('.js-mobile-slider').each(function () {
    let mobileWidth = 576;
    if (
      $(window).width() <= mobileWidth &&
      !$(this).hasClass('slick-initialized')
    ) {
      $(this).slick({
        dots: false,
        arrows: false,
        infinite: false,
        speed: 1000,
        autoplay: false,
        slidesToShow: 1.15,
        slidesToScroll: 1,
      });
    } else if (
      $(window).width() > mobileWidth &&
      $(this).hasClass('slick-initialized')
    ) {
      $(this).slick('unslick');
    }
  });
}

function openMapInit() {
  $(document)
    .find('.js-opensource-map')
    .each(function () {
      let openMap = $(this);
      let openMapPopup = openMap.find('.opensource-map__popup');
      let map = L.map(openMap.attr('id')).setView(
        [openMap.data('lat'), openMap.data('lng')],
        openMap.data('zoom')
      );
      new L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      if (openMapPopup.get(0).innerHTML) {
        var openMapIcon = L.divIcon({
          iconSize: [25, 30],
          popupAnchor: [0, -20],
          className: 'opensource-map__icon',
        });
        L.marker([openMap.data('lat'), openMap.data('lng')], {
          icon: openMapIcon,
        })
          .addTo(map)
          .bindPopup(openMapPopup.get(0).innerHTML)
          .openPopup();
      }
    });
}

// Protected files download
function allowProtected() {
  let isAllowed = getCookie('protected_files_allowed');

  if (isAllowed) {
    $(document)
      .find('.js-download')
      .each(function () {
        $(this).removeClass('fancybox').prop('download', '');
        if ($(this).attr('data-url') != undefined) {
          $(this).attr('href', $(this).attr('data-url'));
        }
      });
    return false;
  }
}

// Cookies
function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
function getCookie(name) {
  let nameEQ = name + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
